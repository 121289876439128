<template>
  <div class="menu" style="z-index: 1000000">
    <div class="sidebar">
      <img class="sidebar-image" src="@/assets/sidebar-image-4.png" />
    </div>
    <div class="socials">
      <a
        href="https://medium.com/softmaxfinance"
        target="_blank"
      >
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/webos.png" />
          <u>M</u>edium
        </div>
      </a>
      <a href="https://twitter.com/softmaxfinance" target="_blank">
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/twitter.png" />
          <u>X</u>&nbsp/Twitter
        </div>
      </a>
      <a href="https://github.com/softmaxfinance" target="_blank">
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/github.png" />
          <u>G</u>itHub
        </div>
      </a>
      <div class="divider"></div>
      <a 
      @click="openWindow()"
      @touchstart="openWindow()" 
      >
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/contact.png" />
          <u>C</u>ontact
        </div>
      </a>
      <a 
      @click="openWindow1()"
      @touchstart="openWindow1()" >
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/resume.png" />
          <u>D</u>ocs
        </div>
      </a>
      <!-- <a
      >
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/zip.png" />
          <u>V</u>aults
        </div>
      </a> -->
      <!-- <a
      >
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/stats.png" />
          <u>P</u>ortfolio
        </div>
      </a> -->
      <!-- <a
      >
        <div class="bar">
          <img
            class="social-image"
            src="@/assets/win95Icons/resume.png"
          />
          <u>D</u>ocs
        </div>
      </a>
      <a
      >
        <div class="bar">
          <img class="social-image" src="@/assets/win95Icons/contact.png" />
          <u>C</u>ontact
        </div>
      </a> -->
    </div>
  </div>
</template>
<style scoped>
.menu {
  width: 165px;
  height: 210px;
  background: black;
  background: rgb(195, 195, 195);
  overflow: hidden;
  border-top: solid rgb(250, 250, 250) 2px;
  border-left: solid rgb(250, 250, 250) 2px;
  border-right: solid rgb(90, 90, 90) 1.5px;
  border-bottom: solid rgb(90, 90, 90) 1.5px;
  box-shadow: 1.5px 1.5px black;
  max-height: 100%;
  max-width: 100%;
  align-items: flex-end;
  outline: rgb(222, 222, 222) 1px solid;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 12px;
}

a {
  color: black;
  text-decoration: none;
}

.sidebar {
  width: 25px;
  background: rgb(123, 125, 123);
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.sidebar-image {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    width: 25px;
    background: rgb(123, 125, 123);
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}

a {
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background: rgb(123, 125, 123);
  border-bottom: white solid 1px;
}

.socials {
  flex-grow: 1;
}

.social-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 6px;
}

.bar {
  display: flex;
  flex-direction: row;
  padding: 6px 10px 6px 10px;
  align-items: center;
}

.bar:hover {
  background: rgb(0, 0, 118);
  color: white;
}

</style>
<script>
import interact from "interactjs";
export default {
  props: {
    windowId: String,
    nameOfWindow: String,
    content_padding_left: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_right: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_top: {
      required: false,
      type: String,
      default: "5%",
    },
    content_padding_bottom: {
      required: false,
      type: String,
      default: "5%",
    },
  },
  data: function () {
    return {
      // ID Name of window (important)
      ComponentName: this.nameOfWindow,

      // window
      Window: {},

      // InteractJS states and modifiers
      // resizeOption: {
      //     edges: {
      //         top: true,
      //         left: true,
      //         bottom: true,
      //         right: true
      //     },
      //     margin: 8,
      //     modifiers: [
      //         interact.modifiers.restrictRect({
      //             restriction: '#screen'
      //         })
      //     ],
      // },
      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "#screen",
            endOnly: true,
          }),
        ],
        allowFrom: "#top-bar",
      },
      // values for interact.js transformation
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      w: 400,
      h: 400,
    };
  },
  computed: {
    style() {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`,
        "--content-padding-left": this.content_padding_left || "15%",
        "--content-padding-right": this.content_padding_right || "15%",
        "--content-padding-top": this.content_padding_top || "5%",
        "--content-padding-bottom": this.content_padding_bottom || "5%",
        "--fullscreen": this.$store.getters.getFullscreenWindowHeight,
      };
    },
  },
  created() {
    this.window = this.$store.getters.getWindowById(this.ComponentName);
  },
  methods: {
    // functions to interact with window state

    closeWindow() {
      const payload = {
        windowState: "close",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow() {
      const payload = {
        windowState: "open",
        windowID: "MailWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow1() {
      const payload = {
        windowState: "open",
        windowID: "NOSSAFLEXWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    minimizeWindow() {
      const payload = {
        windowState: "minimize",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    toggleWindowSize() {
      if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
        const payload = {
          fullscreen: false,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        this.x = this.tempX;
        this.y = this.tempY;
      } else if (
        this.$store.getters.getWindowFullscreen(this.ComponentName) == false
      ) {
        const payload = {
          fullscreen: true,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        const tempX = this.x;
        const tempY = this.y;
        this.tempX = tempX;
        this.tempY = tempY;
        this.x = 0;
        this.y = 0;
      }
      setTimeout(() => {
        document.getElementById("website").src =
          document.getElementById("website").src;
      }, 400);
    },

    setActiveWindow() {
      this.$store.commit("zIndexIncrement", this.ComponentName);
      this.$store.commit("setActiveWindow", this.ComponentName);
    },

    // drag events

    dragmove(event) {
      this.x += event.dx;
      this.y += event.dy;
      this.setActiveWindow();
    },

    resizemove(event) {
      this.w = event.rect.width;
      this.h = event.rect.height;

      this.x += event.deltaRect.left;
      this.y += event.deltaRect.top;
    },
  },
};
</script>