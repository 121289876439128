var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu",staticStyle:{"z-index":"1000000"}},[_vm._m(0),_c('div',{staticClass:"socials"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"divider"}),_c('a',{on:{"click":function($event){return _vm.openWindow()},"touchstart":function($event){return _vm.openWindow()}}},[_vm._m(4)]),_c('a',{on:{"click":function($event){return _vm.openWindow1()},"touchstart":function($event){return _vm.openWindow1()}}},[_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[_c('img',{staticClass:"sidebar-image",attrs:{"src":require("@/assets/sidebar-image-4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://medium.com/softmaxfinance","target":"_blank"}},[_c('div',{staticClass:"bar"},[_c('img',{staticClass:"social-image",attrs:{"src":require("@/assets/win95Icons/webos.png")}}),_c('u',[_vm._v("M")]),_vm._v("edium ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://twitter.com/softmaxfinance","target":"_blank"}},[_c('div',{staticClass:"bar"},[_c('img',{staticClass:"social-image",attrs:{"src":require("@/assets/win95Icons/twitter.png")}}),_c('u',[_vm._v("X")]),_vm._v(" /Twitter ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://github.com/softmaxfinance","target":"_blank"}},[_c('div',{staticClass:"bar"},[_c('img',{staticClass:"social-image",attrs:{"src":require("@/assets/win95Icons/github.png")}}),_c('u',[_vm._v("G")]),_vm._v("itHub ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar"},[_c('img',{staticClass:"social-image",attrs:{"src":require("@/assets/win95Icons/contact.png")}}),_c('u',[_vm._v("C")]),_vm._v("ontact ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar"},[_c('img',{staticClass:"social-image",attrs:{"src":require("@/assets/win95Icons/resume.png")}}),_c('u',[_vm._v("D")]),_vm._v("ocs ")])
}]

export { render, staticRenderFns }