<template>
  <div>
    <img class="self" style="margin-top: 40px;" src="@/assets/logos/softmaxdocs.png" />
    <h2 class="header"><br/><br/>Welcome to the central point of reference for softmaxOS!</h2>
    <h4 class="subtitle" style="padding-bottom: 10px">
     Here you can find all information about the Protocol, technical details reagrding the Vaults and how to interact with them safely. Enjoy! 
    </h4>
    <div class="inner-content">
      <h3 class="heading"><b>Contents</b></h3>
      <ul>
        <li class="paragraph"><a href='#softmaxos'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>softmaxOS</a></li>
        <li class="paragraph"><a href='#vaults'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Softmax Vaults</a></li>
        <li class="paragraph"><a href='#contracts'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Interacting with Contracts</a></li>
        <ul style="margin-top: 8px; margin-bottom: 5px;">
          <li class="paragraph"><a href='#eth'><img src="../../assets/win95Icons/folder.png" style="max-width: 18px; margin-right: 5px; margin-bottom: -3px;"/>ETH Vault</a></li>
          <li class="paragraph"><a href='#matic'><img src="../../assets/win95Icons/folder.png" style="max-width: 18px; margin-right: 5px; margin-bottom: -3px;"/>MATIC Vault</a></li>
          <li class="paragraph"><a href='#sol'><img src="../../assets/win95Icons/folder.png" style="max-width: 18px; margin-right: 5px; margin-bottom: -3px;"/>SOL Vault</a></li>
        </ul>
        <li class="paragraph"><a href='#gov'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Softmax Governance Programme</a></li>
        <li class="paragraph"><a href='#research'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Softmax Research</a></li>
        <li class="paragraph"><a href='#vision'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Vision for Softmax Protocol</a></li>
        <li class="paragraph"><a href='#acknowledgements'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Acknowledgements</a></li>
      </ul>
      <h3 class="heading" id="softmaxos"><br/><br/><b>Introducing softmaxOS</b></h3>
      <p class="paragraph">
        softmaxOS was created to serve as a place for DeFi users to invest, learn and analyse the performance of their funds. 
      </p>
      <p class="paragraph">
      The team at Softmax Finance wanted to provide an easy way for people to interact with DeFi contracts and yeild aggregation strategies direclty, without misleading front-end, giving them full confidence in the safety of their funds.
      </p>
      <h3 class="heading" id="vaults"><br/><br/><b>What are Vaults?</b></h3>
      <p class="paragraph">
        Vaults are the center of the Softmax Protocol offering. Countless hours of R&D led to the creation of regularly updated yield aggregating smart contracts (commonly reffered to as Vaults), 
        where users can deposit their network native tokens (such as ETH) and enjoy low-risk yield on their assets without having to trade them.        
      </p>
      <p class="paragraph">
      The sources of said yield most often include Liquidity Provision, Network Staking or Lending. Instead of having to manually find best APYs across the different platforms offering those yield-bearing protocols, Softmax Finance (amongst other yeild aggregating protocols) aims to make that process easier by automating the search for best yield and the compounding of returns, across several sources.
      </p>
      <h3 class="heading"><br/><br><b>How are the Vaults constructed?</b></h3>
      <p class="paragraph">
        In the first phase of the Softmax Protocol roll-out, the Team decided to focus on the <a href="https://lido.fi/lido-ecosystem" target="_blank" class="inner_a">Lido Ecosystem</a> as the starting point for the three main Vaults: ETH, MATIC and SOL.
      </p>
      <p class="paragraph">
      The Lido Protocol allows users to take advantage of the first of the three sources mentioned above: Network Staking. By providing users with a return on the tokens staked for Network Validation, while issuing a usable token in return (stETH, STMATIC and stSOL), Softmax Protocol can build strategies to find the best possible alloaction for the yield-bearing staked tokens, to maximise user's return on their initial investment. 
      </p>
      <p class="paragraph">
      Further allocation of the staked Lido tokens include eg. <a href="https://balancer.fi" target="_blank" class="inner_a">Balancer Protocol</a> where extra yeild is being generated by providing liquidity to Balancer Pools, as well as other memebrs of the <a href="https://lido.fi/lido-ecosystem" target="_blank" class="inner_a">Lido Ecosystem</a>, depending on where the yield can be maximised. 
      </p>
      <p class="paragraph">
      These include <a href="https://app.aave.com" target="_blank" class="inner_a">Aave Protocol</a>, <a href="https://makerdao.com/en/" target="_blank" class="inner_a">MakerDAO</a>, <a href="https://curve.fi/#/ethereum/swap" target="_blank" class="inner_a">Curve Finance</a> and others that accept Lido Staked Assets in their protocols.
      </p>
      <h3 class="heading"><br/><br><b>Fees?</b></h3>
      <p class="paragraph">
      Each Vault on the Softmax Porotocol has a 10% performance fee, that is being charged to maintain and further develop Softmax Protocol, softmaxOS and fund the Research Lab.
      </p>
      <h3 class="heading" id="contracts"><br/><br/><b>How to invest?</b></h3>
      <p class="paragraph">
        The main focus for the Development Team at Softmax Finance while building softmaxOS and the Vaults, 
        was to provide users with the most transparent way of interacting with the smart contracts possible. 
      </p>
      <p class="paragraph">
        This is why we have decided to give users the option to call the contract functions through our         <a
              class="inner_a"
              @click="openWindow7()"
              @touchstart="openWindow7()"
            >simplified UI</a>, or directly using an open-souce framework called <a href="https://github.com/adrianmcli/eth95" target="_blank" class="inner_a">eth95</a>.
        Thanks to the possibility of direct on-chain control, the doubt of interacting with a malicious contract are eliminated, as all aspects of the transaction can be referenced throughout the process.
      </p>
      <p class="paragraph">
        We are aware this might make things a bit more complicated, which is why we still offer our simplified UI for easier access, and an explicit run-through of the functions for each Vault in case you'd want to go on-chain.
      </p>
      <h3 class="heading" id="eth"><br/><br/><b>ETH Vault</b></h3>
      <p class="paragraph">
      The ETH Vault comes in two versions: 
      <ul>
        <a
              class="inner_a"
              @click="openWindow0()"
              @touchstart="openWindow0()"
            >
        <li class="paragraph"><img src="../../assets/win95Icons/folder.png" style="max-width: 18px; margin-right: 5px; margin-bottom: -3px;"/>ETH DYNAMIC</li>
        </a>
        <a
              class="inner_a"
              @click="openWindow()"
              @touchstart="openWindow()" 
            >
        <li class="paragraph"><img src="../../assets/win95Icons/folder.png" style="max-width: 18px; margin-right: 5px; margin-bottom: -3px;"/>ETH STATIC</li>
        </a>
      </ul> 
      </p>
      <p class="paragraph">
        They both employ the same strategy, with the only difference being a "time-lock" feature on the STATIC verison of the Vault, set to 30 days. This means that after you deposit the funds, you will only be able to withdraw them 30 days after (at the very least).
      </p>
      <p class="paragraph"><br/><b>Strategy overview</b></p>
      <p class="paragraph">
        The funds are being passed onto the Lido Protocol, where they get staked for Liquid Staking Rewards. The returned stETH is being wrapped into wstETH and deposited into appropriate Balancer Pools, upon searching and optimising for the best Pool ID possible, assuring maximum yield from your initial ETH investment and competitive LP rewards regardless of the amount locked in the Vault.
      </p>
      <p class="paragraph"><br/><b>Investing in the ETH Vault</b></p>
      <p class="paragraph">
        If you decide to use the simplified UI, head to the either of the two Vaults linked above, connect your Metamask and enter the amount you'd like to lock in the Vault.
      </p>
      <p class="paragraph">
      To invest in either of the two via the <a href="https://eth95.dev/?network=1&address=0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233" target="_blank" class="inner_a">ETH Vault's eth95 Terminal</a>, call the <i>Invest</i> function within the <i>Functions</i> window. 
      </p>
      <p class="paragraph">
        The <i>Invest</i> function takes two inputs: 
      <ul>
        <li class="paragraph">- InvestType (0 or 1)</li>
        <li class="paragraph">- Amount (Number of ETH)</li>
      </ul> 
      </p>
      <p class="paragraph">
      The first input <i>InvestType</i> is used to distinguish between the two version of the Vault. 
      <ul>
        <li class="paragraph">- For ETH STATIC: <i>InvestType</i> = 0</li>
        <li class="paragraph">- For ETH DYNAMIC: <i>InvestType</i> = 1</li>
      </ul> 
      </p>
      <p class="paragraph">
      The second input <i>Amount</i> is used to decide how much ETH you want to deposit in the Vault. Any positive decimal will work, as long as the wallet has sufficient funds.
      </p>
      <p class="paragraph"><br/><b>Withdrawing from the ETH Vault</b></p>
      <p class="paragraph">
        In the simplified UI you just need to enter the amount you'd like to withdraw (given there is enough funds that you invested in the Vault already) and first unwrap the funds, before withdrawing the full amount.
      </p>
      <p class="paragraph">
        <u>Remember: The order matters!</u> If you try to withdraw your funds before unwrapping them, the system willl throw an error. This is because the stETH funds obtained from Lido are firstly wrapped before they can be used further in the ecosystem.
      </p>
      <p class="paragraph">
      To withdraw funds from either of the two versions of the Vault using the <a href="https://eth95.dev/?network=1&address=0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233" target="_blank" class="inner_a">ETH Vault's eth95 Terminal</a>, first call the <i>withdrawReward</i> function.
      </p>
      <p class="paragraph">
      That will result in your rewards being collected, unwrapped and reverted back to Lido's stETH. 
      </p>
      <p class="paragraph">
      To unstake those funds back into ETH (with all accrued yield), call the <i>withdrawStETHReceived</i> function. 
      </p>
      <p class="paragraph">
        You should then recieve your initial investement with rewards (less performance fee) to your wallet (in ETH).
      </p>
      <p class="paragraph"><br/><b>Other functions</b></p>
      <p class="paragraph">
        The contract has a number of other functions that can help you understand the Vault and your invested amount better.
      </p>
      <p class="paragraph">
        For example, you can check what the current STATIC Vault withdrawal time is by calling the <i>staticPoolWithdrawTime</i> function, or your current Stats regarding the Vaults you are part of by calling the <i>UserInfo</i> function.
      </p>
      <p class="paragraph">
        The information on your current <img src="../../assets/win95Icons/stats.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/><a         
        @click="openWindow5()"
        @touchstart="openWindow5()" 
        ><b>Portfolio</b></a> and Stats will soon be available via our simplified UI as well.
      </p>
      <p class="paragraph">
        You can explore all the other functions within the <a href="https://eth95.dev/?network=1&address=0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233" target="_blank" class="inner_a">ETH Vault's eth95 Terminal</a>, or in the <a         href="https://etherscan.io/address/0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233" target="_blank" class="inner_a">content of the contract</a> itself.
      </p>
      <h3 class="heading" id="matic"><br/><br/><b>MATIC Vault</b></h3>
      <p class="paragraph">
      Coming soon.
      </p>
      <h3 class="heading" id="sol"><br/><br/><b>SOL Vault</b></h3>
      <p class="paragraph">
      Coming soon.
      </p>
      <h3 class="heading" id="gov"><br/><br/><b>How does the Governance Programme work?</b></h3>
      <p class="paragraph">
      Although not yet live, we envision the <img src="../../assets/win95Icons/vault2.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/><a         
        @click="openWindow6()"
        @touchstart="openWindow6()" 
        ><b>Governance</b></a> Programme to be a way for our users to
      voice their needs, share profits in the ecosystem and help shape the future of this Protocol. More importantly, it is a step towards making the decision-making more decentralized.
      </p>
      <p class="paragraph">
      Existing Vault users willing to participate will be able to mint Governance Tokens based on the amount of funds they have locked in the Vaults, and use them to vote on the allocation of the Treasury Funds, share part of the performance fee profits, vote on proposals regarding new Vaults, develpoments and R&D efforts.
      </p>
      <p class="paragraph">
        This functionality will be enabled by the introduction of a Governance smart-contract that will gain ownership of all the Vault contracts, colllecting all fees and distributing them across several maintainance efforts (R&D, Marketing, Functionality, Salaries, Gov Token Holders etc) according to regular vote results.
      </p>
      <p class="paragraph">
      The Softmax Governance Programme is expected to come into effect in the second stage of the Protocol's development - Voyager. Read more about it <a href="#vision" class="inner_a">here</a>.
      </p>
      <h3 class="heading" id="research"><br/><br/><b>What does the Research Lab do?</b></h3>
      <p class="paragraph">
      We belive R&D is a big part of making DeFi a better place. That is why from the very begining a major portion of our efforts goes into funding extensive research in the field of Decentralized Finance, Machine Learning and Financial Computing. 
      </p>
      <p class="paragraph">
      The Team is built around a group of Computer Scientists as well as Financial Markets specialists to deliver most value both in the shape of new Vaults as well as useful articles and papers. 
      </p>
      <p class="paragraph">
      Find out what the latest publications are in the 
      <img src="../../assets/win95Icons/mail.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/><a         
        @click="openWindow4()"
        @touchstart="openWindow4()" 
        ><b>Research</b></a> section.
      </p>
      <h3 class="heading" id="vision"><br/><br/><b>Vision for the Protocol</b></h3>
      <p class="paragraph">
      Taking inspiration from the development of the Ethereum Ecosystem itself, the core Softmax Finance Team decided to outline 4 stages in the development of the Softmax Protocol,
      that would subsequentially mark the steps on the projects Roadmap.
      </p>
      <p class="paragraph">
        The 4 stages are:
        <ul>
          <li class="paragraph"><a href='#pioneer'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Pioneer</a></li>
          <li class="paragraph"><a href='#voyager'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Voyager</a></li>
          <li class="paragraph"><a href='#apollo'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>Apollo</a></li>
          <li class="paragraph"><a href='#horizons'><img src="../../assets/win95Icons/directory.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>New Horizons</a></li>
        </ul> 
      </p>
      <p class="paragraph" id="pioneer"><br/><b>Pioneer (Current Stage)</b></p>
      <p class="paragraph">
        The first stage - Pioneer - marks the begining of Softmax Finance and the creation of the Protocol.
        With the growth of DeFi and liquid staking becoming more popular, we saw a opportunity to build a yield aggregation protocol around it, with transparency in mind.
        This phase includes the creation of the first Vaults, softmaxOS and the establishment of the Softmax Reserach Team, that aims to grow the Protocol further.
      </p>
      <p class="paragraph" id="voyager"><br/><b>Voyager</b></p>
      <p class="paragraph">
        In the second stage of growth we expect to embark on the most important part of the journey. This is where we expand the decentralization of the Protocol, 
        introduce the Softmax Governance Programme (discussed <a href="#gov" class="inner_a">here</a>) and introduce more Vaults (including ones on Polygon and Solana networks).
      </p>
      <p class="paragraph">
        Here is also where we see the Team expanding, new UI features being added (such as Portfolio tracking, customization of the eth95 Terminal etc) and the direction for new developments being set by the Community.
      </p>
      <p class="paragraph" id="apollo"><br/><b>Apollo</b></p>
      <p class="paragraph">
        In the third stage, Softmax Finance ventures beyond it's established niche within yeild aggregation. New product offerings, expanding beyond the current scope, emerge and a big part of the development efforts are focused on R&D.
      </p>
      <p class="paragraph">
        At this point, most major decision are being voted by the users within the Governance Programme and the decision making process is being moved on-chain to eliminate the need for human trust and custody.
      </p>
      <p class="paragraph" id="horizons"><br/><b>New Horizons</b></p>
      <p class="paragraph">
        In its final form Softmax Finance is envisioned to be fully decentralized, with 100% of fees delivered to members of the Governance Programme and all decisions being made within it, aiming for a full DAO experience. 
      </p>
      <p class="paragraph">
        This is also where we expect DeFi to take a new role in the context of the financial markts, as the industry matures and becomes more institutionalized. Softmax Finance strives to be an active part of that process as our Reserach Team helps develop cutting-edge DeFi solutions.
      </p>
      <h3 class="heading" id="acknowledgements"><br/><br/><b>Acknowledgements</b></h3>
      <p class="paragraph">
      The possibilities that Softmax Finance offers to it's users would never be possible without prior innovation in the field. It's the ingenuity of ecosystems such as <a href="https://lido.fi/lido-ecosystem" target="_blank" class="inner_a">Lido Finance</a> an all its members, that allows our Protocol to harness the power of Decentralized Finance, and take you assets further. 
      </p>
      <p class="paragraph">
        softmaxOS as a platform also heavily relies on previous inventions of the industry. Most importantly, <a href="https://github.com/adrianmcli/eth95" target="_blank" class="inner_a">eth95</a> allowed our users to embrace the power of Softmax smart contracts, without worrying about trusting us with delivering that power through yet another DeFi front-end. 
      </p>
      <p class="paragraph">
        The deceptively simple yet charming Windows95-esque aesthetic of the <a href="https://github.com/adrianmcli/eth95" target="_blank" class="inner_a">eth95</a> Terminal inspired us to deliver the rest of softmaxOS in a simmilar fashion. It gave our message clarity, while keeping the exilerating feeling of the early days of Personal Computing.
      </p>
      <p class="paragraph">
        To achieve that, the Team used the help of webOS - an open-source project by <a href="https://github.com/DonChiaQE" target="_blank" class="inner_a">Don Chia</a> that massively sped up the development of the project.     
        As for the livefeed of on-chain data, <a href="https://etherscan.io" target="_blank" class="inner_a">Etherscan</a> and <a href="https://dune.com" target="_blank" class="inner_a">Dune Analytics</a> are credited as source.
      </p>
      <p class="paragraph">
        <br/>Thank you to everyone who made Softmax Finance possible.
      </p>
      <p class="paragraph">
        <br/>Sincerely,
      </p>
      <p class="paragraph" style="font-style: italic;">
        Softmax Finance Team
      </p>
    </div>
    <!-- <div class="inner-content">
      <h3 class="heading"><b>Apple WWDC Scholar 2021</b></h3>
      <p class="paragraph">
        My second try at Apple's international coding competition was finally a
        successful one. Making use of my prowess with web development, I decided
        to create a Swift Playground project that taught users how to make use
        of HTML and CSS to create a simple website.
      </p>
      <p class="paragraph">
        Making use of Apple's SwiftUI framework as well as the nifty WKWebView
        API, I managed to create a working editor that would allow users to
        meddle with HTML and CSS. I proceeded to create a playground book
        project around that idea and came up with a tutorial sequence for the
        user to follow.
      </p>
      <p class="paragraph">
        Check out my submission
        <a style="color: #ff5733" href="https://github.com/DonChiaQE/WWDC2021"
          >here</a
        >!
      </p>
      <p class="paragraph">
        And check out my interview with the local news outlet regarding
        NOSSAFLEX and my WWDC experience
        <a
          style="color: #ff5733"
          href="https://www.youtube.com/watch?v=RwtzXxSSJAc"
          >right here</a
        >!
      </p>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>Apple WWDC Scholar 2022</b></h3>
      <p class="paragraph">
        Yet another successful attempt at Apple's international coding
        competition. By making use of SwiftUI once more, I created a short app
        experience that explores recursive art patterns and abstract art. With
        the help of generative art, I was able to create a unique experience
        that most people would have rarely come across.
      </p>
      <p class="paragraph">
        Check out my submission
        <a style="color: #ff5733" href="https://github.com/DonChiaQE/ReGen"
          >here</a
        >!
      </p>
      <p class="paragraph">
        As this news has only just recently reached me, I will be updating this
        section once more when I have receive more news from Apple HQ.
      </p>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>Internships</b></h3>
      <p class="paragraph">
        I have had two internship opportunities so far. The first internship was
        at a local SME firm that created a variety of software technologies for
        projects and the second internship was a local startup Fintech firm.
      </p>
      <p class="paragraph">
        Throughout the internships I worked with a mixture of iOS and Web
        Technologies. Storyboarding and UIKit was used in my first tech
        internship and Vuejs with Bootstrap was used in my second internship as
        I was a frontend intern.
      </p>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>iOS Developer</b></h3>
      <p class="paragraph">
        As listed above, I have over a year of experience with SwiftUI and have
        had plenty of projects that features the use of SwiftUI. SwiftUI was
        used in most of the components building NOSSAFLEX and the components
        featured required custom styling to fit our themeing. I have also had
        the chance to participate in two WWDC Student Challenges in 2020 as well
        as 2021, both projects featured SwiftUI and a variety of other Swift
        technologies.
      </p>
      <p class="paragraph">
        Here are some of the projects I have contributed towards.
        <a style="color: #ff5733" href="https://github.com/DonChiaQE/WWDC2021"
          >[Submission for WWDC 2021]</a
        >
        <a
          style="color: #ff5733"
          href="https://github.com/DonChiaQE/push-pop-push-views-swiftui-example"
          >[Push and Popping Solution for SwiftUI]</a
        >
      </p>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>Web Developer</b></h3>
      <p class="paragraph">
        As a web developer, I have also had a lot of chances to practice and
        participate in projects to further hone my skills. I have plenty of
        experience with backend Python as well as databases using SQL or NoSQL
        solutions. But most of my time is dedicated to frontend development
        using Vuejs and elaborate styling of websites (such as this one 😁).
      </p>
      <p class="paragraph">
        Here are some of the projects I have contributed towards.
        <a
          style="color: #ff5733"
          href="https://github.com/dunmanhigh-isac/dhap-2021"
          >[MUN Website for School]</a
        >
        <a
          style="color: #ff5733"
          href="https://github.com/DonChiaQE/marketplace"
          >[CMS Marketplace]</a
        >
        <a style="color: #ff5733" href="https://github.com/zaydek/duomo"
          >[SCSS Library]</a
        >
      </p>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>UI/UX Designer</b></h3>
      <p class="paragraph">
        Fullstack development is very sought after nowadays and I have made sure
        to keep up with such trends by honing my skills in UI/UX design as well.
        I am thankful for the various opportunities I have had to work alongside
        industry expert and have also taken the chance to learn and hone my
        UI/UX design skills with tools such as Figma.
      </p>
    </div> -->

    <!-- <div class="inner-content">
                            <div class="heading"><b>Interests and Hobbies</b></div>
                            <div class="paragraph">Analog photography and colorizing of historical photos have always interested me and some of my photographic works are displayed <span style="cursor: pointer; color: #FF5733;" v-on:click="showPhotos">here</span> while you can find my colorization portfolio <span style="cursor: pointer; color: #FF5733;" v-on:click="showColorization">here</span>.</div>
                        </div> -->

    <!-- <div class="inner-content">
      <h3 class="heading"><b>Résumé</b></h3>
      <a
        style="color: #ff5733"
        href="/files/don_chia_resume.pdf"
        class="paragraph"
        >Check out my résumé here.</a
      >
    </div> -->
  </div>
</template>

<style scoped>
.badge-grid {
  display: table;
  align-items: center;
  margin-top: 20px;
}

.badge {
  width: auto;
  height: 20px;
  border-radius: 0px;
  font-size: 12px;
  padding-right: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
}

.self {
  width: 100%;
  height: auto;
  /* border-radius: 5px; */
}

.subtitle {
  font-size: 12px;
  color: gray;
}

.heading {
  padding-bottom: 5px;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.inner-content {
  padding-top: 30px;
}

h2,
h4,
h3 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: black !important;
}

a:hover {
  color: #ff5733 !important;
  cursor: pointer;
}

a:visited {
  text-decoration: none;
}

.inner_a {
  text-decoration: none;
  color: #ff5733 !important;
}

.inner_a:hover {
  color: black !important;
  cursor: pointer;
}

.inner_a:visited {
  text-decoration: none;
}

</style>

<script>
import interact from "interactjs";
export default {
  props: {
    windowId: String,
    nameOfWindow: String,
    content_padding_left: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_right: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_top: {
      required: false,
      type: String,
      default: "5%",
    },
    content_padding_bottom: {
      required: false,
      type: String,
      default: "5%",
    },
  },
  data: function () {
    return {
      // ID Name of window (important)
      ComponentName: this.nameOfWindow,

      // window
      Window: {},

      // InteractJS states and modifiers
      // resizeOption: {
      //     edges: {
      //         top: true,
      //         left: true,
      //         bottom: true,
      //         right: true
      //     },
      //     margin: 8,
      //     modifiers: [
      //         interact.modifiers.restrictRect({
      //             restriction: '#screen'
      //         })
      //     ],
      // },
      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "#screen",
            endOnly: true,
          }),
        ],
        allowFrom: "#top-bar",
      },
      // values for interact.js transformation
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      w: 400,
      h: 400,
    };
  },
  computed: {
    style() {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`,
        "--content-padding-left": this.content_padding_left || "15%",
        "--content-padding-right": this.content_padding_right || "15%",
        "--content-padding-top": this.content_padding_top || "5%",
        "--content-padding-bottom": this.content_padding_bottom || "5%",
        "--fullscreen": this.$store.getters.getFullscreenWindowHeight,
      };
    },
  },
  created() {
    this.window = this.$store.getters.getWindowById(this.ComponentName);
  },
  methods: {
    // functions to interact with window state

    closeWindow() {
      const payload = {
        windowState: "close",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow4() {
      const payload = {
        windowState: "open",
        windowID: "AppleWWDC2021",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow5() {
      const payload = {
        windowState: "open",
        windowID: "WebOSWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow6() {
      const payload = {
        windowState: "open",
        windowID: "AppleWWDC2022",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow7() {
      const payload = {
        windowState: "open",
        windowID: "PhotosWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow0() {
      const payload = {
        windowState: "open",
        windowID: "Vaultethdynamic",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow() {
      const payload = {
        windowState: "open",
        windowID: "Vaulteth",
      };
      this.$store.commit("setWindowState", payload);
    },

    minimizeWindow() {
      const payload = {
        windowState: "minimize",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    toggleWindowSize() {
      if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
        const payload = {
          fullscreen: false,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        this.x = this.tempX;
        this.y = this.tempY;
      } else if (
        this.$store.getters.getWindowFullscreen(this.ComponentName) == false
      ) {
        const payload = {
          fullscreen: true,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        const tempX = this.x;
        const tempY = this.y;
        this.tempX = tempX;
        this.tempY = tempY;
        this.x = 0;
        this.y = 0;
      }
      setTimeout(() => {
        document.getElementById("website").src =
          document.getElementById("website").src;
      }, 400);
    },

    setActiveWindow() {
      this.$store.commit("zIndexIncrement", this.ComponentName);
      this.$store.commit("setActiveWindow", this.ComponentName);
    },

    // drag events

    dragmove(event) {
      this.x += event.dx;
      this.y += event.dy;
      this.setActiveWindow();
    },

    resizemove(event) {
      this.w = event.rect.width;
      this.h = event.rect.height;

      this.x += event.deltaRect.left;
      this.y += event.deltaRect.top;
    },
  },
};
</script>