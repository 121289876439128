<template>
  <div>
    <div class="top-bar-nav">
        <div class="top-bar-text">
          <a
            href="https://eth95.dev/?network=1&address=0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233"
            target="_blank"
          >
          <span style="margin-right: 12px"><u>E</u>th95 Terminal</span>
          </a>
          <a
            href="#eth"
            style="z-index: 10"
            @click="openWindow2()"
            @touchstart="openWindow2()"
          >
          <span style="margin-right: 12px"><u>U</u>ser Guide </span>
          </a>
          <a
            href="https://etherscan.io/address/0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233"
            target="_blank"
          >
          <span style="margin-right: 12px"><u>E</u>therscan </span>
          </a>
          
        </div>
        <div class="divider2" style="margin-top: 6px;"></div>
      </div>
    <h3 class="heading" v-if="!isSmallScreen" style="margin-top: 30px;">
      <b class="title">
        <img src="../../assets/win95Icons/folder.png" style="max-width: 30px; margin-right: 7px; margin-bottom: -6px;"/>
        ETH Vault
      </b>
      <div class="connect-button" @click="connectWallet">{{ connectButtonText }}</div>
    </h3>
    <h3 class="heading" v-else style="margin-top: 30px;">
      <b class="title">ETH Vault</b>
      <div class="connect-button" @click="connectWallet">{{ connectButtonText }}</div>
    </h3>

    <div class="button-container">
      <div class="button-like" id="apyValue">{{ apyValue }}</div>
      <div class="button-like" id="tvlValue">{{ tvlValue }}</div>
    </div>
    <h4 class="subtitle" v-if="!isSmallScreen" style="padding-bottom: 5px; margin-top: 5px;">
      The above values indicate the APY for this type of Vault and the TVL for the entire ETH Vault.
    </h4>
    <h4 class="subtitle" v-else style="padding-bottom: 5px; margin-top: 5px;">
      The above indicate APY and TVL for this Vault.
    </h4>
    <div class="divider"></div>
    <h3 class="heading">
      <b class="title" style="margin-top: 30px; margin-left: 5px;">Invest</b>
    </h3>
    <div class="input-container">
      <div class="input-labels">
        <div class="input-label" v-if="!isSmallScreen">Vault Type:</div>
        <div class="input-label" v-else>Type:</div>
        <div class="input-label" v-if="!isSmallScreen">Amount:</div>
        <div class="input-label" v-else>ETH:</div>
      </div>
      <div class="input-fields">
        <div class="input-field">
          <select v-model="investType" class="custom-dropdown">
            <option value="0">Static</option>
            <option value="1">Dynamic</option>
          </select>        
        </div>
        <div class="input-field">
          <input type="number" id="ethAmount" v-model="ethAmount" step="0.01" />
        </div>
      </div>
    </div>
    <div class="input-result">You will lock: {{ ethAmount }} ETH
    </div>
    <div class="connect-button" @click="lockInVault" :class="{ inactive: !connectedAddress || !web3 }" style="margin-top: 20px;">
    {{ connectedAddress ? 'Lock in Vault' : 'No Wallet Connected' }}
    </div> 
   
    
      <div class="divider" style="margin-top: 30px;"></div> 
      <h3 class="heading">
      <b class="title" style="margin-top: 30px; margin-left: 5px;">Unwrap & Withdraw</b>
    </h3>
      <div class="input-container">
        <div class="input-labels">
          <div class="input-label" v-if="!isSmallScreen">Amount:</div>
        <div class="input-label" v-else>ETH:</div>
        </div>
        <div class="input-fields">
          <div class="input-field">
            <input type="number" id="withdrawEthAmount" v-model="withdrawEthAmount" step="0.01" />
          </div>
        </div>
      </div>

    <div class="connect-button" @click="unwrapFunds" :class="{ inactive: !connectedAddress || !web3 }" style="margin-top: 10px; margin-bottom: 20px;">
    {{ connectedAddress ? 'Unwrap Funds' : 'No Wallet Connected' }}
    </div>
    <div class="connect-button" @click="withdrawFunds" :class="{ inactive: !connectedAddress || !web3 }" style="margin-top: 10px; margin-bottom: 20px;">
    {{ connectedAddress ? 'Withdraw Funds' : 'No Wallet Connected' }}
    </div>
    </div>
</template>

<style scoped>

.subtitle {
  font-size: 12px;
  color: gray;
}

a:visited {
  text-decoration: none;
}

a {
  text-decoration: none;
  color: black !important;
}
.top-bar-nav {
    position: fixed;
    top: 25px;
    left: 0;
    width: 100%;
    background-color: #c3c3c3;
  }

  .top-bar-text {
    display: flex;
    align-items: center;
    margin-top: 6px;
    margin-left: 12px;
  }

  .top-bar-text span {
    margin-right: 12px;

    cursor: pointer;
  }

  .top-bar-text span:hover {
    text-decoration: underline;
  }

.input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}


.input-labels {
  /* margin-right: 5px; */
  /* flex: 1; */
  width: 46%;
}

.input-label {
  width: 100%;
  height: 100%;

  height: 40px; /* Adjust height as needed */
  margin-bottom: 10px;
  box-shadow: none;
  background: repeating-conic-gradient(
      rgb(189, 190, 189) 0% 25%,
      rgb(255, 255, 255) 0% 50%
    )
    50% / 2px 2px;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;


  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px; /* Adjust font size as needed */
  font-weight: bold;

}

.input-fields {
  margin-right: 3px;
  /* flex: 1; */
  width: 46%;
}
/* 
.input-container .input-labels + .input-fields {
    margin-left: 8px;
  } */

.input-field {
  width: 100%;
  height: 100%;

  height: 40px; /* Adjust height as needed */
  margin-bottom: 10px;
  box-shadow: none;
  background: repeating-conic-gradient(
      rgb(189, 190, 189) 0% 25%,
      rgb(255, 255, 255) 0% 50%
    )
    50% / 2px 2px;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px; /* Adjust font size as needed */
  font-weight: bold;

}

.input-field input[type="number"] {

  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
}

.input-result {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 5px;
}

.inactive {
  cursor: not-allowed;
  background: repeating-conic-gradient(rgb(189, 190, 189) 0% 25%, rgb(255, 255, 255) 0% 50%) 50% / 2px 2px !important;
  border-top: solid rgb(0, 0, 0) 1.5px !important;
  border-left: solid rgb(0, 0, 0) 1.5px !important;
  border-bottom: solid rgb(250, 250, 250) 1.5px !important;
  border-right: solid rgb(250, 250, 250) 1.5px !important;
  color: #888;
  box-shadow: none !important;
}

.custom-dropdown {
  /* Apply button-like styles to dropdown */
  width: 100%;
  height: 100%;
  box-shadow: none;
  background: repeating-conic-gradient(rgb(189, 190, 189) 0% 25%, rgb(255, 255, 255) 0% 50%) 50% / 2px 2px;
  /* border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px; */
  border-radius: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: black; /* Set text color */
  padding: 5px;
  appearance: none; /* Remove system default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Style the dropdown options */
.custom-dropdown option {
  font-size: 16px;
  font-weight: bold;
  color: black; /* Set text color */
  padding: 5px;
  display: flex;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background: rgb(123, 125, 123);
  border-bottom: white solid 1px;
}

.divider2 {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background: rgb(0, 0, 0);
  border-bottom: white solid 1px;
}
.heading {
  padding-bottom: 5px;
}

h6,
h3 {
  margin: 0;
  padding: 0;
}

.button-like {
  width: 46%; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  box-shadow: none;
  background: repeating-conic-gradient(
      rgb(189, 190, 189) 0% 25%,
      rgb(255, 255, 255) 0% 50%
    )
    50% / 2px 2px;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px; /* Adjust font size as needed */
  font-weight: bold;

  }

.button {
    width: 200px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    box-shadow: 1.5px 1.5px black;
    border-top: solid rgb(250, 250, 250) 1.5px;
    border-left: solid rgb(250, 250, 250) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    border-right: solid rgb(90, 90, 90) 1.5px;
    background: rgb(192, 192, 192);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px; /* Adjust font size as needed */
    font-weight: bold;

  }

    /* Style for the container div */
.button-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
  }
  /* Margin to separate the two buttons */
.button-container .button-like + .button-like {
    margin-left: 10px; /* Adjust the margin as needed */
  }



.heading {
  display: flex;
  justify-content: space-between; /* Create space between title and button */
  align-items: center;
}

.title {
  margin-right: 10px;
}

.connect-button {
  box-shadow: 1.5px 1.5px black;
  border-top: solid rgb(250, 250, 250) 1.5px;
  border-left: solid rgb(250, 250, 250) 1.5px;
  border-bottom: solid rgb(90, 90, 90) 1.5px;
  border-right: solid rgb(90, 90, 90) 1.5px;
  background: rgb(192, 192, 192);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  padding: 8px 15px;
}

.connect-button:active {
  box-shadow: none;
  background: repeating-conic-gradient(
      rgb(189, 190, 189) 0% 25%,
      rgb(255, 255, 255) 0% 50%
    )
    50% / 2px 2px;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;
}

</style>

<script>
import Web3 from 'web3';

export default {
  data() {
    return {
      connectButtonText: "Connect Wallet",
      investType: 1,
      ethAmount: 0,
      withdrawEthAmount: 0,
      apyValue: "Loading...",
      tvlValue: "Loading...",
      connectedAddress: '',
      web3: null,
      isSmallScreen: false,
    };
  },

  methods: {

    openWindow2() {
      const payload = {
        windowState: "open",
        windowID: "NOSSAFLEXWindow",
      };
      this.$store.commit("setWindowState", payload);
    },
    // functions to interact with window state
    async fetchAndDisplayTotalETH() {
    try {
      const apiKey = '9KSEANG1X7YTBSNGJ2GWYFNVIFYDQ6QSZD'; // Replace with your Etherscan API key
      const contractAddress = '0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233'
      // const url = `https://api.etherscan.io/api?module=stats&action=ethsupply2&apikey=${apiKey}`;
      const url = "https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=0x32296969Ef14EB0c6d29669C550D4a0449130230&address=0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233&tag=latest&apikey=9KSEANG1X7YTBSNGJ2GWYFNVIFYDQ6QSZD";
      const response = await fetch(url);
      const data = await response.json();

      const ethSupply = data.result;
      const totalETH = ethSupply / 1e18; // Convert total ETH value to the desired unit (e.g., in ETH)

      const url2 = "https://api.etherscan.io/api?module=stats&action=ethprice&apikey=9KSEANG1X7YTBSNGJ2GWYFNVIFYDQ6QSZD";
      const response2 = await fetch(url2);
      const data2 = await response2.json();

      const ETHprice = data2.result['ethusd']
      const tvlUSD = totalETH * ETHprice

      this.tvlValue = `${tvlUSD.toFixed(2)} USD`;

    } catch (error) {
      console.error('Error fetching TVL data:', error);
    }
  },

    async fetchAndCalculateAPR() {
    try {
      const apiKey = 'GcUkWmgbcJtH3Pp4OU3cq3GEkmW25FRk'; // Replace with your Dune Analytics API key

      // Fetch data from the first query to get the instant staking ARP value
      const urlQuery1 = `https://api.dune.com/api/v1/query/570874/results?api_key=GcUkWmgbcJtH3Pp4OU3cq3GEkmW25FRk`;
      const responseQuery1 = await fetch(urlQuery1);
      const dataQuery1 = await responseQuery1.json();

      const instantAPR = dataQuery1.result.rows[0]['protocol_apr'];
      const last30Rows = dataQuery1.result.rows.slice(-30);
      const sumProtocolAPR = last30Rows.reduce((sum, row) => sum + row['protocol_apr'], 0);
    
      const monthaverageAPY1 = sumProtocolAPR / 30;

      // Fetch data from the second query to get the Total APR value
      const urlQuery2 = `https://api.dune.com/api/v1/query/701599/results?api_key=GcUkWmgbcJtH3Pp4OU3cq3GEkmW25FRk`;
      const responseQuery2 = await fetch(urlQuery2);
      const dataQuery2 = await responseQuery2.json();

      const totalAPR = dataQuery2.result.rows[0]['Total APR,%'];
      const last30RowsQuery2 = dataQuery2.result.rows.reverse().slice(-30);
      const sumTotalAPR = last30RowsQuery2.reduce((sum, row) => sum + row["Total APR,%"], 0);
      
      const monthaverageAPY2 = sumTotalAPR / 30;

      // Calculate the total APR by adding the calculated APR from the first query to the Total APR from the second query
      const finalAPR = (instantAPR + totalAPR).toFixed(2);
      const finalmonthAPR = (monthaverageAPY1 + monthaverageAPY2).toFixed(2);
      const protocolAPR = ((Number(finalAPR) + Number(finalmonthAPR)) / 2).toFixed(2);

      this.defaultapyValue = `${finalAPR} %`
      this.apyValue2 = `${finalmonthAPR} %`;
      this.apyValue = `${finalAPR} %`;
   
      // Alternatively, you can display it in the desired location(s) by selecting the element and setting its innerText:
      // const aprElement = document.getElementById('calculatedAPR');
      // aprElement.innerText = `${finalAPR}%`;
    } catch (error) {
      console.error('Error fetching and calculating APR:', error);
    }
  },

  async connectWallet() {
    try {
      const provider = await detectEthereumProvider();

      if (provider) {
        if (this.connectedAddress) {
          // If the wallet is already connected, disconnect it
          this.connectedAddress = '';
          this.web3 = null;
        } else {
          // Request access to the user's MetaMask account
          const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

          if (accounts.length > 0) {
            // User connected the wallet
            this.connectedAddress = accounts[0];
            console.log('Connected Address:', this.connectedAddress);

            this.web3 = new Web3(provider);
            console.log('Web3 Session:', this.web3);
          }
        }
        this.updateConnectButtonText();
      } else {
        // MetaMask is not installed
        alert('MetaMask is not installed. Please install it to connect your wallet.');
      }
    } catch (error) {
      console.error('Error connecting/disconnecting wallet:', error);
    }
  },

  // Add a new method to update the connectButtonText
  updateConnectButtonText() {
    if (this.connectedAddress) {
      // If the wallet is connected, display the truncated address
      const firstFew = this.connectedAddress.slice(0, 6);
      const lastFew = this.connectedAddress.slice(-4);
      this.connectButtonText = `${firstFew}...${lastFew}`;
    } else {
      this.connectButtonText = 'Connect Wallet';
    }
  },

    // Function to handle locking in the vault
    async lockInVault() {
  // Check if the wallet is connected
  if (!this.connectedAddress || !this.web3) {
        alert("Wallet not connected or Web3 not initialized.");
        return;
  }

  // Validate input
  if (this.ethAmount <= 0) {
    alert("Please enter a valid amount.");
    return;
  }

  try {
    // Get the contract address from the ABI
    const contractAddress = "0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233";

    // Get the contract ABI
    const contractABI = [
	{
		"inputs": [
			{
				"internalType": "enum SoftmaxETHVault.InvestType",
				"name": "_investType",
				"type": "uint8"
			}
		],
		"name": "invest",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wstETH",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_vault",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_changeCommission",
				"type": "uint256"
			}
		],
		"name": "updateCommission",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_poolId",
				"type": "bytes32"
			}
		],
		"name": "updatePoolId",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_staticPoolWithdrawTime",
				"type": "uint256"
			}
		],
		"name": "updateStaticWithdrawTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "updatedVaultAddress",
				"type": "address"
			}
		],
		"name": "updateVault",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "updatedWstethAddress",
				"type": "address"
			}
		],
		"name": "updateWstethAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "withdrawReward",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "withdrawStETHReceived",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "commission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "getLidoRewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_account",
				"type": "address"
			}
		],
		"name": "getWstETHBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "poolId",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "staticPoolWithdrawTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "userInfo",
		"outputs": [
			{
				"internalType": "enum SoftmaxETHVault.InvestType",
				"name": "investType",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "investmentTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "investedAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedWstETHBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedBPTBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedStETHBalance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userTotalInvestments",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "vault",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wstETH",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

    // Create a new contract instance
    const contract = new this.web3.eth.Contract(contractABI, contractAddress);

    // Convert the investType to uint8 (0 for Static and 1 for Dynamic)
    const investType = this.investType === "1" ? 1 : 0;

    // Convert the ETH amount to Wei (1 ETH = 1e18 Wei)
    const amountInWei = this.web3.utils.toWei(this.ethAmount, "ether");

    // Call the invest function
    const response = await contract.methods.invest(investType).send({
      from: this.connectedAddress,
      value: amountInWei,
      // gas: 160000,
    });

    // Process the response if needed
    console.log("Investment successful:", response);
    alert("Investment successful.");
	this.ethAmount = 0;
  } catch (error) {
    console.error("Error investing:", error);
    alert("Error investing. Please try again.");
	this.ethAmount = 0;
  }
  },

  async unwrapFunds() {
      if (!this.connectedAddress || !this.web3) {
        alert("Wallet not connected or Web3 not initialized.");
        return;
      }

      if (this.withdrawEthAmount <= 0) {
        alert("Please enter a valid amount.");
        return;
      }

      try {
        // Get the contract address from the ABI
        const contractAddress = "0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233";

        // Get the contract ABI
       const contractABI = [
	{
		"inputs": [
			{
				"internalType": "enum SoftmaxETHVault.InvestType",
				"name": "_investType",
				"type": "uint8"
			}
		],
		"name": "invest",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wstETH",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_vault",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_changeCommission",
				"type": "uint256"
			}
		],
		"name": "updateCommission",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_poolId",
				"type": "bytes32"
			}
		],
		"name": "updatePoolId",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_staticPoolWithdrawTime",
				"type": "uint256"
			}
		],
		"name": "updateStaticWithdrawTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "updatedVaultAddress",
				"type": "address"
			}
		],
		"name": "updateVault",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "updatedWstethAddress",
				"type": "address"
			}
		],
		"name": "updateWstethAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "withdrawReward",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "withdrawStETHReceived",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "commission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "getLidoRewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_account",
				"type": "address"
			}
		],
		"name": "getWstETHBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "poolId",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "staticPoolWithdrawTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "userInfo",
		"outputs": [
			{
				"internalType": "enum SoftmaxETHVault.InvestType",
				"name": "investType",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "investmentTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "investedAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedWstETHBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedBPTBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedStETHBalance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userTotalInvestments",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "vault",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wstETH",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];
        // Create a new contract instance
        const contract = new this.web3.eth.Contract(contractABI, contractAddress);

        // Convert the ETH amount to Wei (1 ETH = 1e18 Wei)
        const amountInWei = this.web3.utils.toWei(this.withdrawEthAmount.toString(), "ether");

        // Call the withdrawReward function to unwrap funds
        const response = await contract.methods.withdrawReward(amountInWei).send({
          from: this.connectedAddress,
          gas: 160000,
        });

        // Process the response if needed
        console.log("Funds unwrapped successfully:", response);
        alert("Funds unwrapped successfully.");
      } catch (error) {
        console.error("Error unwrapping funds:", error);
        alert("Error unwrapping funds. Please try again.");
      }
    },

    // Function to handle withdrawing funds
    async withdrawFunds() {
      if (!this.connectedAddress || !this.web3) {
        alert("Wallet not connected or Web3 not initialized.");
        return;
      }

      if (this.withdrawEthAmount <= 0) {
        alert("Please enter a valid amount.");
        return;
      }

      try {
        // Get the contract address from the ABI
        const contractAddress = "0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233";

        const contractABI = [
	{
		"inputs": [
			{
				"internalType": "enum SoftmaxETHVault.InvestType",
				"name": "_investType",
				"type": "uint8"
			}
		],
		"name": "invest",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wstETH",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_vault",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_changeCommission",
				"type": "uint256"
			}
		],
		"name": "updateCommission",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_poolId",
				"type": "bytes32"
			}
		],
		"name": "updatePoolId",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_staticPoolWithdrawTime",
				"type": "uint256"
			}
		],
		"name": "updateStaticWithdrawTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "updatedVaultAddress",
				"type": "address"
			}
		],
		"name": "updateVault",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "updatedWstethAddress",
				"type": "address"
			}
		],
		"name": "updateWstethAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "withdrawReward",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "withdrawStETHReceived",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "commission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_count",
				"type": "uint256"
			}
		],
		"name": "getLidoRewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_account",
				"type": "address"
			}
		],
		"name": "getWstETHBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "poolId",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "staticPoolWithdrawTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "userInfo",
		"outputs": [
			{
				"internalType": "enum SoftmaxETHVault.InvestType",
				"name": "investType",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "investmentTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "investedAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedWstETHBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedBPTBalance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "receivedStETHBalance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userTotalInvestments",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "vault",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wstETH",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];
        // Create a new contract instance
        const contract = new this.web3.eth.Contract(contractABI, contractAddress);

        // Convert the ETH amount to Wei (1 ETH = 1e18 Wei)
        const amountInWei = this.web3.utils.toWei(this.withdrawEthAmount.toString(), "ether");

        // Call the withdrawStETHReceived function to withdraw funds
        const response = await contract.methods.withdrawStETHReceived(amountInWei).send({
          from: this.connectedAddress,
          gas: 160000,
        });

        // Process the response if needed
        console.log("Funds withdrawn successfully:", response);
        alert("Funds withdrawn successfully.");
		this.withdrawEthAmount = 0;
      } catch (error) {
        console.error("Error withdrawing funds:", error);
        alert("Error withdrawing funds. Please try again.");
		this.withdrawEthAmount = 0;
      }
    },
  },

  mounted() {
    // Fetch and display Total ETH value (TVL) on component mount
    this.fetchAndDisplayTotalETH();

    // Fetch and calculate APR value (APY) on component mount
    this.fetchAndCalculateAPR();

    if (this.connectedAddress) {
    this.web3 = new Web3(window.ethereum);
    console.log('Web3 initialized:', this.web3);
  };

  this.isSmallScreen = window.innerWidth <= 1000;

// Add an event listener to update the screen size variable on resize
  window.addEventListener('resize', () => {
  this.isSmallScreen = window.innerWidth <= 1000;
});

  },

  watch: {
    investType: function (newInvestType) {
      if (newInvestType === '0') {
        // If static is selected, set the apyValue to finalmonthAPR
        this.apyValue = this.apyValue2;
      } else {
        // If dynamic is selected, set the apyValue to finalAPR
        this.apyValue = this.defaultapyValue;
      }
    },
  },

};


</script>