import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Height of Fullscreen Window
    fullscreenWindowHeight: window.innerHeight + "px",

    // Active Window State
    activeWindow: "BiographyWindow",

    // Active Windows Array State
    activeWindows: [],

    // Z-index State
    zIndex: 2,

    photoFiles: [
      {
        id: 0,
        title: "ETH Vault",
        content: [
        ],
        size: 5109084,
        type: "folder",
        altText: "ETH Vault",
      },
      {
        id: 1,
        title: "MATIC Vault",
        content: [
        ],
        size: 18318406,
        type: "foldermatic",
        altText: "MATIC Vault",
      },
      {
        id: 2,
        title: "SOL Vault",
        content: [
          // {
          //   id: 0,
          //   title: "RB67-17.JPG",
          //   type: "file",
          //   src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-17.JPG",
          //   altText: "RB67-17.JPG",
          //   size: 4018715,
          // },
          // {
          //   id: 1,
          //   title: "RB67-16.JPG",
          //   type: "file",
          //   src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-16.JPG",
          //   altText: "RB67-16.JPG",
          //   size: 6310380,
          // },
        ],
        size: 78680605,
        type: "foldersol",
        altText: "SOL Vault",
      },
    ],

    photoFolderContent: [],

    windows: [
      {
        windowId: "BiographyWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "About", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "bio", // Window Content (used under slots)
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "30vw", // Window Position X (when first opened)
        positionY: "5%", // Window Position Y (when first opened)
        iconImage: "bio.png", // Window Icon Image
        altText: "About", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "WebOSWindow",
        windowState: "close",
        displayName: "Portfolio",
        windowComponent: "window",
        windowContent: "stats",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "stats.png",
        altText: "Portfolio",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "OpenSourceWindow",
        windowState: "close",
        displayName: "Protocol",
        windowComponent: "window",
        windowContent: "opensource",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "opensource.png",
        altText: "Protocol",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      // {
      //   windowId: "ResumeWindow", // Unique ID
      //   windowState: "close", // Window State [open, close, minimize]
      //   displayName: "Résumé", // Display Name (title under icon)
      //   windowComponent: "window", // Window Component (can be changed to use modified windows)
      //   windowContent: "resume", // Window Content (used under slots)
      //   windowContentPadding: {
      //     top: "0",
      //     right: "0",
      //     bottom: "0",
      //     left: "0",
      //   }, // Window Content Padding
      //   position: "absolute", // Window Position
      //   positionX: "10vw", // Window Position X (when first opened)
      //   positionY: "15vh", // Window Position Y (when first opened)
      //   iconImage: "resume.png", // Window Icon Image
      //   altText: "Résumé", // Window Icon Alt Text
      //   fullscreen: false, // Window Fullscreen State [true, false]
      //   showInAppGrid: true,
      //   showInNavbar: true,
      // },
      {
        windowId: "ImagePreviewWindow",
        windowState: "close",
        displayName: "Media Viewer",
        windowComponent: "ImagePreviewWindow",
        windowContent: "",
        windowContentPadding: {
          top: "1px",
          right: "10px",
          bottom: "10px",
          left: "10px",
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "image.png",
        altText: "Photos",
        fullscreen: false,
        showInAppGrid: false,
        showInNavbar: false,
        // imagePreview: file.src
      },
      {
        windowId: "NOSSAFLEXWindow",
        windowState: "close",
        displayName: "Docs",
        windowComponent: "window",
        windowContent: "nossaflex",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "resume.png",
        altText: "Documentation",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "AppleWWDC2021",
        windowState: "close",
        displayName: "Research",
        windowComponent: "window",
        windowContent: "wwdc2021",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "mail.png",
        altText: "Research",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "Vaulteth",
        windowState: "close",
        displayName: "ETH Vault",
        windowComponent: "window",
        windowContent: "vaulteth",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "folder.png",
        altText: "ETH Vault",
        fullscreen: false,
        showInAppGrid: false,
        showInNavbar: true,
      },
      {
        windowId: "Vaultethdynamic",
        windowState: "close",
        displayName: "ETH Vault",
        windowComponent: "window",
        windowContent: "vaultethdynamic",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "folder.png",
        altText: "ETH Vault",
        fullscreen: false,
        showInAppGrid: false,
        showInNavbar: true,
      },
      {
        windowId: "Vaultsol",
        windowState: "close",
        displayName: "SOL Vault",
        windowComponent: "window",
        windowContent: "vaultsol",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "folder.png",
        altText: "SOL Vault",
        fullscreen: false,
        showInAppGrid: false,
        showInNavbar: true,
      },
      {
        windowId: "Vaultmatic",
        windowState: "close",
        displayName: "MATIC Vault",
        windowComponent: "window",
        windowContent: "vaultmatic",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "folder.png",
        altText: "MATIC Vault",
        fullscreen: false,
        showInAppGrid: false,
        showInNavbar: true,
      },
      {
        windowId: "AppleWWDC2022",
        windowState: "close",
        displayName: "Governance",
        windowComponent: "window",
        windowContent: "wwdc2022",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "vault2.png",
        altText: "Governance",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "MailWindow",
        windowState: "close",
        displayName: "Contact",
        windowComponent: "mail",
        windowContent: "",
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "contact.png",
        altText: "Contact",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      // register your new windows here
    ],
    mailContent: "",
    mailSender: "",
    mailSubject: "New Message",
  },
  mutations: {
    // Active Window Mutator
    setActiveWindow(state, window) {
      console.log(window);
      state.activeWindow = window;
    },

    pushNewWindow(state, window) {
      state.windows.push(window);
    },

    // Pushes Active Window onto Active Windows Array
    pushActiveWindow(state, window) {
      state.activeWindows.push(window);
    },

    // Removes Active Window from Active Windows Array
    popActiveWindow(state, window) {
      const windowIndex = state.activeWindows.indexOf(window);
      if (windowIndex !== -1) {
        state.activeWindows.splice(windowIndex, 1);
      }
    },

    // Z-index increment function
    zIndexIncrement(state, windowID) {
      state.zIndex += 1;
      document.getElementById(windowID).style.zIndex = state.zIndex;
    },

    // Set height of max-height of fullscreen window
    setFullscreenWindowHeight(state, height) {
      state.fullscreenWindowHeight = height;
    },

    updateMail(state, local) {
      state.isShownMail = local;
    },
    updateMailSubject(state, local) {
      state.mailSubject = local;
    },
    updateMailContent(state, local) {
      state.mailContent = local;
    },
    updateMailSender(state, local) {
      state.mailSender = local;
    },

    setFullscreen(state, payload) {
      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();
      window.fullscreen = payload.fullscreen;
    },

    setPhotoFolderContent(state, payload) {
      state.photoFolderContent = payload;
    },

    setCurrentVideo(state, payload) {
      state.currentVideo = payload;
    },

    // Window State Mutator
    setWindowState(state, payload) {
      // payload = {'windowState': 'open', 'windowID': 'WindowOne'}

      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();

      var preventAppendingOpenWindow = false;
      if (window.windowState == "open" || window.windowState == "minimize") {
        preventAppendingOpenWindow = true;
      }

      if (payload.windowState == "open") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("zIndexIncrement", payload.windowID);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", payload.windowID);
        }, 0);
        if (preventAppendingOpenWindow == false) {
          setTimeout(() => {
            this.commit("pushActiveWindow", window);
          }, 0);
        }
      } else if (payload.windowState == "close") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("popActiveWindow", window);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", "nil");
        }, 0);
      } else if (payload.windowState == "minimize") {
        window.windowState = payload.windowState;
        this.commit("setActiveWindow", "nil");
      } else {
        console.log("Error: windowState not found or invalid");
      }
    },
  },
  getters: {
    // Active Window Getter
    getActiveWindow: (state) => {
      return state.activeWindow;
    },

    // Window Getter
    getWindowById: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id);
    },

    getWindowFullscreen: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id).fullscreen;
    },

    getPhotoFolderContent(state) {
      return state.photoFolderContent;
    },

    getPhotoFiles: (state) => {
      return state.photoFiles;
    },

    getSongAlbumFiles: (state) => {
      return state.songAlbumFiles;
    },

    getVideoFiles: (state) => {
      return state.videoFiles;
    },

    getCurrentVideo: (state) => {
      return state.currentVideo;
    },

    getWindows: (state) => {
      return state.windows;
    },

    getActiveWindows(state) {
      return state.activeWindows;
    },

    getFullscreenWindowHeight(state) {
      return state.fullscreenWindowHeight;
    },

    mailContent: (state) => {
      return state.mailContent;
    },

    mailSubject: (state) => {
      return state.mailSubject;
    },

    mailSender: (state) => {
      return state.mailSender;
    },
  },
});
