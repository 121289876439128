var render = function render(){var _vm=this,_c=_vm._self._c;return _c('interact',{staticClass:"window window-style",class:{
    fullscreen: _vm.$store.getters.getWindowFullscreen(this.ComponentName),
    minimize:
      _vm.$store.getters.getWindowById(_vm.ComponentName).windowState == 'minimize',
  },style:(_vm.style),attrs:{"draggable":"","dragOption":_vm.dragOption,"resizable":"","resizeOption":_vm.resizeOption},on:{"dragmove":_vm.dragmove,"resizemove":_vm.resizemove},nativeOn:{"click":function($event){return _vm.setActiveWindow.apply(null, arguments)}}},[_c('div',{staticClass:"top-bar-window",class:_vm.$store.getters.getActiveWindow == this.ComponentName
        ? 'top-bar'
        : 'top-bar-deactivated',attrs:{"id":"top-bar"},on:{"dblclick":_vm.toggleWindowSize}},[_c('div',{staticClass:"window-name"},[_c('img',{staticClass:"icon-image",attrs:{"src":require('@/assets/win95Icons/' + this.window.iconImage),"alt":_vm.window.altText}}),_vm._v(_vm._s(this.window.displayName)+" ")]),_c('div',{staticClass:"triple-button"},[_c('button',{staticClass:"minimize-button button",on:{"click":_vm.minimizeWindow}},[_c('span',{staticStyle:{"height":"2px","width":"6px","background":"black","margin-top":"8px","margin-right":"2px"}})]),_c('button',{staticClass:"expand-button button",on:{"click":_vm.toggleWindowSize}},[_c('span',{staticStyle:{"height":"8px","width":"9px","border-left":"black 1px solid","border-right":"black 1px solid","border-bottom":"black 1px solid","border-top":"black 2px solid"}})]),_c('button',{staticClass:"close-button button",staticStyle:{"margin-right":"3px","padding-left":"1px"},on:{"click":_vm.closeWindow}},[_vm._v(" × ")])])]),_c('div',{staticClass:"content"},[_vm._t("content")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }