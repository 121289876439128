<template>
  <div>
    <img class="self" style="margin-top: 40px;" src="@/assets/logos/softmaxprotocol.png" />
    <div class="button-container">
      <div class="button-like" > {{ defaultapyValue }}</div>
      <div class="button-like" >
      {{ tvlValue }} <!-- Placeholder text for Total ETH value -->
     </div>
    </div>    
    <h4 class="subtitle" style="padding-bottom: 10px">
      The above values indicate an average value of APY across the entire Protocol and the Total Value Locked across all Vaults.
    </h4>
    <div class="inner-content">
      <table class="button-like-table">
        <tr>
          <th>Vault</th>
          <th>APY</th>
          <th>TVL (USD)</th>
        </tr>
        <tr>
          <td v-if="!isSmallScreen">
            <a
              class="icon-photos"
              @click="openWindow0()"
              @touchstart="openWindow0()"
            >
            <img src="../../assets/win95Icons/folder.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>ETH DYNAMIC
          </a>
          </td>
          <td v-else>
          <a
              class="icon-photos"
              @click="openWindow0()"
              @touchstart="openWindow0()"
            >
            ETH D..
          </a>
          </td>
            <td >{{apyValue}}</td>
            <td >{{tvlValue2}}</td>
        </tr>
        <tr>
          <td v-if="!isSmallScreen">
            <a
              class="icon-photos"
              @click="openWindow()"
              @touchstart="openWindow()" 
            >
            <img src="../../assets/win95Icons/folder.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>ETH STATIC
            </a>
          </td>
          <td v-else>
            <a
              class="icon-photos"
              @click="openWindow()"
              @touchstart="openWindow()" 
            >
            ETH S..
            </a>
          </td>
          <td >{{apyValue2}}</td>
          <td >{{tvlValue2}}</td>
        </tr>
        <tr>
          <td v-if="!isSmallScreen">
            <a
              class="icon-photos"
              @click="openWindow1()"
              @touchstart="openWindow1()" 
            >
            <img src="../../assets/win95Icons/folder.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>MATIC
            </a>
          </td>
          <td v-else>
            <a
              class="icon-photos"
              @click="openWindow1()"
              @touchstart="openWindow1()" 
            >
            MATIC
            </a>
          </td>
          <td class="subtitle">COMING SOON</td>
          <td class="subtitle">COMING SOON</td>
        </tr>
        <tr>
          <td v-if="!isSmallScreen">
            <a
              class="icon-photos"
              @click="openWindow2()"
              @touchstart="openWindow2()" 
            >
            <img src="../../assets/win95Icons/folder.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>SOL
            </a>
          </td>
          <td v-else>
            <a
              class="icon-photos"
              @click="openWindow2()"
              @touchstart="openWindow2()" 
            >
            SOL
            </a>
          </td>
          <td class="subtitle">COMING SOON</td>
          <td class="subtitle">COMING SOON</td>
        </tr>
      </table>

    </div>

    <div class="inner-content" style="margin-bottom: 30px;">
      <h3 class="heading"><br/><b>Invest smarter, not harder with Softmax.</b></h3>
      <p class="paragraph">
        Softmax Protocol utilizes on-chain data and a variety of Machine Learning techniques to build optimal Vaults for Lido Staked Assets, that combine the existing DeFi platforms in the Lido Ecosystem with the most advanced yield aggregating techniques.
      </p>
      <p class="paragraph">
        You can find more about what Vaults are, how to invest in them, and how they are constructed in more detail within the <img src="../../assets/win95Icons/resume.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>
        <a         
        @click="openWindow3()"
        @touchstart="openWindow3()" 
        ><b>Docs</b></a> folder.
      </p>
      </div>

  </div>
</template>

<style scoped>
.self {
  width: 100%;
  height: auto;
  /* border-radius: 5px; */
}

.icon-photos:hover {
  cursor: pointer;

}

.subtitle {
  font-size: 12px;
  color: gray;
}

.heading {
  padding-bottom: 20px;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.inner-content {
  padding-top: 30px;
}

h2,
h4,
h3 {
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: black !important;
}

a:hover {
  color: #ff5733 !important;
  cursor: pointer;
}

a:visited {
  text-decoration: none;
}

table {
    border-collapse: collapse;
    width: 100%;
  }

th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }

.button-like {
    width: 200px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    box-shadow: none;
  background: repeating-conic-gradient(
      rgb(189, 190, 189) 0% 25%,
      rgb(255, 255, 255) 0% 50%
    )
    50% / 2px 2px;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px; /* Adjust font size as needed */
    font-weight: bold;

  }

    /* Style for the container div */
.button-container {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 20px;
    justify-content: space-between;
  }
  /* Margin to separate the two buttons */
.button-container .button-like + .button-like {
    margin-left: 10px; /* Adjust the margin as needed */
  }

.button-like-table {
    width: 100%;
    box-shadow: 1.5px 1.5px black;
    border-top: solid rgb(250, 250, 250) 1.5px;
    border-left: solid rgb(250, 250, 250) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    border-right: solid rgb(90, 90, 90) 1.5px;
    background: rgb(192, 192, 192);
    border-collapse: collapse;
  }
.button-like-table th,
.button-like-table td {
    padding: 10px;
    text-align: left;
    border-top: solid rgb(250, 250, 250) 1.5px;
    border-left: solid rgb(250, 250, 250) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    border-right: solid rgb(90, 90, 90) 1.5px;
  }

</style>

<script>

export default {

  data() {
    return {
      defaultapyValue: "Loading...",
      apyValue: "Loading...",
      apyValue2: "Loading...",
      tvlValue: "Loading...",
      tvlValue2: "Loading...",
    };
  },

  methods: {

    openWindow0() {
      const payload = {
        windowState: "open",
        windowID: "Vaultethdynamic",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow() {
      const payload = {
        windowState: "open",
        windowID: "Vaulteth",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow1() {
      const payload = {
        windowState: "open",
        windowID: "Vaultmatic",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow2() {
      const payload = {
        windowState: "open",
        windowID: "Vaultsol",
      };
      this.$store.commit("setWindowState", payload);
    },
    
    openWindow3() {
      const payload = {
        windowState: "open",
        windowID: "NOSSAFLEXWindow",
      };
      this.$store.commit("setWindowState", payload);
    },
    // functions to interact with window state
    async fetchAndDisplayTotalETH() {
    try {
      const apiKey = '9KSEANG1X7YTBSNGJ2GWYFNVIFYDQ6QSZD'; // Replace with your Etherscan API key
      const contractAddress = '0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233'
      // const url = `https://api.etherscan.io/api?module=stats&action=ethsupply2&apikey=${apiKey}`;
      const url = "https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=0x32296969Ef14EB0c6d29669C550D4a0449130230&address=0x1DB2f2868B7dbb90d1d71ea7f54A37e22a4D1233&tag=latest&apikey=9KSEANG1X7YTBSNGJ2GWYFNVIFYDQ6QSZD";
      const response = await fetch(url);
      const data = await response.json();

      const ethSupply = data.result;
      const totalETH = ethSupply / 1e18; // Convert total ETH value to the desired unit (e.g., in ETH)

      const url2 = "https://api.etherscan.io/api?module=stats&action=ethprice&apikey=9KSEANG1X7YTBSNGJ2GWYFNVIFYDQ6QSZD";
      const response2 = await fetch(url2);
      const data2 = await response2.json();

      const ETHprice = data2.result['ethusd']
      const tvlUSD = totalETH * ETHprice

      this.tvlValue = `${tvlUSD.toFixed(2)} USD`;
      this.tvlValue2 = `${tvlUSD.toFixed(2)}`;

    } catch (error) {
      console.error('Error fetching TVL data:', error);
    }
    },

    async fetchAndCalculateAPR() {
    try {
      const apiKey = 'GcUkWmgbcJtH3Pp4OU3cq3GEkmW25FRk'; // Replace with your Dune Analytics API key

      // Fetch data from the first query to get the instant staking ARP value
      const urlQuery1 = `https://api.dune.com/api/v1/query/570874/results?api_key=GcUkWmgbcJtH3Pp4OU3cq3GEkmW25FRk`;
      const responseQuery1 = await fetch(urlQuery1);
      const dataQuery1 = await responseQuery1.json();

      const instantAPR = dataQuery1.result.rows[0]['protocol_apr'];
      const last30Rows = dataQuery1.result.rows.slice(-30);
      const sumProtocolAPR = last30Rows.reduce((sum, row) => sum + row['protocol_apr'], 0);
    
      const monthaverageAPY1 = sumProtocolAPR / 30;

      // Fetch data from the second query to get the Total APR value
      const urlQuery2 = `https://api.dune.com/api/v1/query/701599/results?api_key=GcUkWmgbcJtH3Pp4OU3cq3GEkmW25FRk`;
      const responseQuery2 = await fetch(urlQuery2);
      const dataQuery2 = await responseQuery2.json();

      const totalAPR = dataQuery2.result.rows[0]['Total APR,%'];
      const last30RowsQuery2 = dataQuery2.result.rows.reverse().slice(-30);
      const sumTotalAPR = last30RowsQuery2.reduce((sum, row) => sum + row["Total APR,%"], 0);
      
      const monthaverageAPY2 = sumTotalAPR / 30;

      // Calculate the total APR by adding the calculated APR from the first query to the Total APR from the second query
      const finalAPR = (instantAPR + totalAPR).toFixed(2);
      const finalmonthAPR = (monthaverageAPY1 + monthaverageAPY2).toFixed(2);
      const protocolAPR = ((Number(finalAPR) + Number(finalmonthAPR)) / 2).toFixed(2);

      this.defaultapyValue = `${protocolAPR} %`
      this.apyValue2 = `${finalmonthAPR} %`;
      this.apyValue = `${finalAPR} %`;
   
      // Alternatively, you can display it in the desired location(s) by selecting the element and setting its innerText:
      // const aprElement = document.getElementById('calculatedAPR');
      // aprElement.innerText = `${finalAPR}%`;
    } catch (error) {
      console.error('Error fetching and calculating APR:', error);
    }
    },

  },

  mounted() {
    // Fetch and display Total ETH value (TVL) on component mount
    this.fetchAndDisplayTotalETH();

    // Fetch and calculate APR value (APY) on component mount
    this.fetchAndCalculateAPR();

    this.isSmallScreen = window.innerWidth <= 1000;

    // Add an event listener to update the screen size variable on resize
    window.addEventListener("resize", () => {
      this.isSmallScreen = window.innerWidth <= 1000;
    });
  },

};

</script>

