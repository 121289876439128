<template>
  <div>
    <img class="self" style="margin-top: 40px;" src="@/assets/logos/logoalt3.png" />
    <h2 class="header"><br/><br/>Unlock the full potential of DeFi Ecosystems and your Liquid Staked Assets.</h2>
    <h4 class="subtitle" style="padding-bottom: 10px">
      Softmax Finance is a Yield Aggregation Protocol and a DeFi Research Lab, 
      focused on Liquid Staked Assets and AI-based solutions in the Decentralized Finance space.
    </h4>
    <!-- <div class="badge-grid">
      <img
        class="badge"
        alt="Swift"
        src="https://img.shields.io/badge/-Swift-orange"
      />
      <img
        class="badge"
        alt="Python"
        src="https://img.shields.io/badge/-Python-blue"
      />
      <img
        class="badge"
        alt="JavaScript"
        src="https://img.shields.io/badge/-Javascript-yellow"
      />
      <img
        class="badge"
        alt="SQL"
        src="https://img.shields.io/badge/-SQL-lightgrey"
      />
      <img
        class="badge"
        alt="Git"
        src="https://img.shields.io/badge/-Git-critical"
      />
      <img
        class="badge"
        alt="HTML/CSS"
        src="https://img.shields.io/badge/-HTML%2FCSS-yellowgreen"
      />
      <img
        class="badge"
        alt="SwiftUI"
        src="https://img.shields.io/badge/-SwiftUI-3e8bc3"
      />
      <img
        class="badge"
        alt="Vue"
        src="https://img.shields.io/badge/-VueJS-61b083"
      />
      <img
        class="badge"
        alt="Flask"
        src="https://img.shields.io/badge/-Flask-406893"
      />
      <img
        class="badge"
        alt="MongoDB"
        src="https://img.shields.io/badge/-MongoDB-4ca158"
      />
      <img
        class="badge"
        alt="SQLite"
        src="https://img.shields.io/badge/-SQLite-64a2c9"
      />
      <img
        class="badge"
        alt="Figma"
        src="https://img.shields.io/badge/-Figma-8c5aee"
      />
    </div> -->
    <div class="inner-content">
      <h3 class="heading"><b>Welcome to softmaxOS</b></h3>
      <p class="paragraph">
        softmaxOS allows you to stake assets on the Softmax Protocol, currently aggregating yield 
        for Liquid Staked Assets from the Lido Ecosystem and the rest of the DeFi landscape.
      </p>
      <p class="paragraph">
        Read more about the capabilites in the <img src="../../assets/win95Icons/opensource.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>
        <a         
        @click="openWindow2()"
        @touchstart="openWindow2()" 
        ><b>Protocol</b></a> section, as well as the official <img src="../../assets/win95Icons/resume.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/><a         
        @click="openWindow3()"
        @touchstart="openWindow3()" 
        ><b>Docs</b></a> folder.
      </p>
      <p class="paragraph">
        It is also a central place for users to track the performance of their <img src="../../assets/win95Icons/stats.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>
        <a         
        @click="openWindow6()"
        @touchstart="openWindow6()" 
        ><b>Portfolio</b></a> and read the latest updates from the Softmax Research team, that publishes
        their R&D endavours regularly within softmaxOS.
      
      </p>
      <p  class="paragraph">
        Here you can find everything you need to make your assets grow, 
        or even participate in the <img src="../../assets/win95Icons/vault2.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>
        <a         
        @click="openWindow5()"
        @touchstart="openWindow5()" 
        ><b>Governance</b></a> Programme to shape the future of DeFi. 
     </p>
      <p class="paragraph" style="margin-bottom: 30px;">
        Check out our current solutions in the <img src="../../assets/win95Icons/zip.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>
        <a         
        @click="openWindow()"
        @touchstart="openWindow()" 
        ><b>Vaults</b></a> directory, and the latest publications in the <img src="../../assets/win95Icons/mail.png" style="max-width: 20px; margin-right: 5px; margin-bottom: -3px;"/>
        <a         
        @click="openWindow4()"
        @touchstart="openWindow4()" 
        ><b>Research</b></a> department.
      </p>
    </div>
  </div>
</template>

<style scoped>
.badge-grid {
  display: table;
  align-items: center;
  margin-top: 20px;
}

.badge {
  width: auto;
  height: 20px;
  border-radius: 0px;
  font-size: 12px;
  padding-right: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
}

.self {
  width: 100%;
  height: auto;
  /* border-radius: 5px; */
}

.subtitle {
  font-size: 12px;
  color: gray;
}

.heading {
  padding-bottom: 5px;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.inner-content {
  padding-top: 30px;
}

h2,
h4,
h3 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: black !important;
}

a:hover {
  color: #ff5733 !important;
  cursor: pointer;
}

a:visited {
  text-decoration: none;
}
</style>

<script>
import interact from "interactjs";
export default {
  props: {
    windowId: String,
    nameOfWindow: String,
    content_padding_left: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_right: {
      required: false,
      type: String,
      default: "15%",
    },
    content_padding_top: {
      required: false,
      type: String,
      default: "5%",
    },
    content_padding_bottom: {
      required: false,
      type: String,
      default: "5%",
    },
  },
  data: function () {
    return {
      // ID Name of window (important)
      ComponentName: this.nameOfWindow,

      // window
      Window: {},

      // InteractJS states and modifiers
      // resizeOption: {
      //     edges: {
      //         top: true,
      //         left: true,
      //         bottom: true,
      //         right: true
      //     },
      //     margin: 8,
      //     modifiers: [
      //         interact.modifiers.restrictRect({
      //             restriction: '#screen'
      //         })
      //     ],
      // },
      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "#screen",
            endOnly: true,
          }),
        ],
        allowFrom: "#top-bar",
      },
      // values for interact.js transformation
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      w: 400,
      h: 400,
    };
  },
  computed: {
    style() {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`,
        "--content-padding-left": this.content_padding_left || "15%",
        "--content-padding-right": this.content_padding_right || "15%",
        "--content-padding-top": this.content_padding_top || "5%",
        "--content-padding-bottom": this.content_padding_bottom || "5%",
        "--fullscreen": this.$store.getters.getFullscreenWindowHeight,
      };
    },
  },
  created() {
    this.window = this.$store.getters.getWindowById(this.ComponentName);
  },
  methods: {
    // functions to interact with window state

    closeWindow() {
      const payload = {
        windowState: "close",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow() {
      const payload = {
        windowState: "open",
        windowID: "PhotosWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow2() {
      const payload = {
        windowState: "open",
        windowID: "OpenSourceWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow3() {
      const payload = {
        windowState: "open",
        windowID: "NOSSAFLEXWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow4() {
      const payload = {
        windowState: "open",
        windowID: "AppleWWDC2021",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow5() {
      const payload = {
        windowState: "open",
        windowID: "AppleWWDC2022",
      };
      this.$store.commit("setWindowState", payload);
    },

    openWindow6() {
      const payload = {
        windowState: "open",
        windowID: "WebOSWindow",
      };
      this.$store.commit("setWindowState", payload);
    },

    minimizeWindow() {
      const payload = {
        windowState: "minimize",
        windowID: this.ComponentName,
      };
      this.$store.commit("setWindowState", payload);
    },

    toggleWindowSize() {
      if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
        const payload = {
          fullscreen: false,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        this.x = this.tempX;
        this.y = this.tempY;
      } else if (
        this.$store.getters.getWindowFullscreen(this.ComponentName) == false
      ) {
        const payload = {
          fullscreen: true,
          windowID: this.ComponentName,
        };
        this.$store.commit("setFullscreen", payload);
        const tempX = this.x;
        const tempY = this.y;
        this.tempX = tempX;
        this.tempY = tempY;
        this.x = 0;
        this.y = 0;
      }
      setTimeout(() => {
        document.getElementById("website").src =
          document.getElementById("website").src;
      }, 400);
    },

    setActiveWindow() {
      this.$store.commit("zIndexIncrement", this.ComponentName);
      this.$store.commit("setActiveWindow", this.ComponentName);
    },

    // drag events

    dragmove(event) {
      this.x += event.dx;
      this.y += event.dy;
      this.setActiveWindow();
    },

    resizemove(event) {
      this.w = event.rect.width;
      this.h = event.rect.height;

      this.x += event.deltaRect.left;
      this.y += event.deltaRect.top;
    },
  },
};
</script>